import React, { Component } from 'react'

import YearPicker from '../common/YearPicker'

import moment from 'moment'
import Backend from '../../../utils/Backend'
import Config from '../../../utils/Config'
import Notify from '../../../utils/Notify'
export default class Files extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: Config.getStartYear(true),
      isMemoLoading: true,
      isNotesLoading: true,
      memoData: [],
      unsecuredNotesData: [],
    }
    this._loadMemoranda()
    this._loadSeniorUnsecuredNotes()
  }

  _fileDownloaded(slug) {
    Backend.updateDownloadCount(slug)
      .then(() => {
        // do nothing
      })
      .catch((err) => {
        Notify.error(err.message)
      })
  }

  _loadMemoranda() {
    this.setState({ isMemoLoading: true })
    Backend.getReports('offering_memoranda', this.state.selectedDate)
      .then((data) => {
        this.setState({ memoData: data, isMemoLoading: false })
      })
      .catch((err) => {
        this.setState({ isMemoLoading: false })
      })
  }

  _loadSeniorUnsecuredNotes() {
    this.setState({ isNotesLoading: true })
    Backend.getReports('senior_unsecured_notes', this.state.selectedDate)
      .then((data) => {
        this.setState({ unsecuredNotesData: data, isNotesLoading: false })
      })
      .catch((err) => {
        this.setState({ isNotesLoading: false })
      })
  }

  _renderMemoLoading() {
    if (!this.state.isMemoLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _renderNotesLoading() {
    if (!this.state.isNotesLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _renderNoResults(data, title) {
    let { isLoading } = this.state
    if (data.length <= 0 && !isLoading) {
      return (
        <div className="text-center mb-12">
          <h6
            className="mb-5"
            id="credit-ratings"
            data-kt-scroll-offset="{default: 100, lg: 150}"
          >
            {title}
          </h6>
        </div>
      )
    }
  }

  _renderRows(data) {
    if (!data) return null
    if (data.length === 0) {
      return null
    }
    return data.map((item) => {
      return (
        <tr>
          <td>
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <a
                  href="#"
                  className="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  {item.title}
                </a>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                  {moment(item.published_at).format('Do MMM YYYY')}
                </span>
              </div>
            </div>
          </td>
          <td>
            <a
              href={item.file_url}
              target="_blank"
              onClick={() => this._fileDownloaded(item.slug)}
              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Download File"
            >
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 22H3C2.4 22 2 21.6 2 21C2 20.4 2.4 20 3 20H21C21.6 20 22 20.4 22 21C22 21.6 21.6 22 21 22ZM13 13.4V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V13.4H13Z"
                    fill="black"
                  />
                  <path
                    opacity="0.3"
                    d="M7 13.4H17L12.7 17.7C12.3 18.1 11.7 18.1 11.3 17.7L7 13.4Z"
                    fill="black"
                  />
                </svg>
              </span>
            </a>
          </td>
        </tr>
      )
    })
  }

  render() {
    let { memoData, unsecuredNotesData } = this.state
    return (
      <div className="py-10 py-lg-20 bg-light">
        <div className="container">
          <div className="text-center mb-12">
            <h2
              className="mb-5"
              id="files"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              OFFERING MEMORANDA
            </h2>
            <hr />
          </div>
          <div className="card mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-1 mb-1">
                  Avolon File Library
                </span>
                <span className="text-muted mt-1 fw-bold fs-7"></span>
              </h3>
              <div className="card-toolbar">
                <div
                  className="d-flex justify-content-end"
                  data-kt-user-table-toolbar="base"
                >
                  <YearPicker
                    startYear={Config.getStartYear(true)}
                    activeItem={this.state.selectedDate}
                    onItemClicked={(item) =>
                      this.setState({ selectedDate: item }, () => {
                        this._loadMemoranda()
                        this._loadSeniorUnsecuredNotes()
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="card-body py-3 row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table align-middle gs-0 gy-4">
                    <thead>
                      <tr className="fw-bolder text-muted bg-light">
                        <th className="ps-4 min-w-325px rounded-start">
                          Offering Memoranda
                        </th>
                        <th className="ps-4 rounded-start" />
                      </tr>
                    </thead>
                    <tbody>{this._renderRows(memoData)}</tbody>
                  </table>
                  {this._renderNoResults(memoData, 'No memoranda available')}
                  {this._renderMemoLoading()}
                </div>
              </div>
              {unsecuredNotesData.length > 0 && (
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table align-middle gs-0 gy-4">
                      <thead>
                        <tr className="fw-bolder text-muted bg-light">
                          <th className="ps-4 min-w-325px rounded-start">
                            Amendments to Existing Senior Unsecured Notes
                          </th>
                          <th className="ps-4 rounded-start" />
                        </tr>
                      </thead>

                      <tbody>{this._renderRows(unsecuredNotesData)}</tbody>
                    </table>
                    {this._renderNoResults(
                      unsecuredNotesData,
                      'No amendments available'
                    )}
                    {this._renderNotesLoading()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
